<template>
  <ul class="menu">
    <ClientOnly>
      <li
        v-if="null === userStore.currentUser"
        class="menu__item account"
        @click="modalStore.toggleModal(accountModal, { component: 'signIn' })"
      >
        <div class="menu__image">
          <img src="~~/assets/icons/account-header.svg" alt="account button" />
        </div>
      </li>
      <li v-else class="menu__item account">
        <MyLink class="menu__item-link" name="account_profile">
          <div class="menu__image account">
            <img src="~~/assets/icons/account-logo.svg" alt="account button" />
          </div>
        </MyLink>
      </li>
      <li v-if="null !== userStore.currentUser" class="menu__item favorites">
        <MyLink class="menu__item-link" name="account_favorites">
          <div class="menu__image">
            <img
              src="~~/assets/icons/favorite-header.svg"
              alt="favorite button"
            />
            <div v-if="favoriteProducts.length > 0" class="menu__count">
              <span class="menu__number">{{ favoriteProducts.length }}</span>
            </div>
          </div>
        </MyLink>
      </li>
      <li
        v-else
        class="menu__item favorites"
        @click="modalStore.toggleModal(accountModal, { component: 'signIn' })"
      >
        <div class="menu__image">
          <img
            src="~~/assets/icons/favorite-header.svg"
            alt="favorite button"
          />
        </div>
      </li>
      <li class="menu__item comparison">
        <MyLink class="menu__item-link" name="comparison">
          <div class="menu__image">
            <img
              src="~~/assets/icons/comparison-header.svg"
              alt="comparison button"
            />
            <div v-if="comparisonProducts.length > 0" class="menu__count">
              <span class="menu__number">{{ comparisonProducts.length }}</span>
            </div>
          </div>
        </MyLink>
      </li>
      <li class="menu__item cart" @click="modalStore.toggleModal(cartModal)">
        <div class="menu__image">
          <img src="~~/assets/icons/cart-header.svg" alt="cart button" />
          <div v-if="quantity > 0" class="menu__count">
            <span class="menu__number">{{ quantity }}</span>
          </div>
        </div>
      </li>
    </ClientOnly>
  </ul>
</template>

<script setup>
import { useCartStore } from "~~/store/cartStore";
import { useComparisonStore } from "~~/store/comparisonStore";
import {
  accountModal,
  cartModal,
  useModalStore,
} from "~/modules/shared/modals/store/modalStore";
import { useUserStore } from "~/store/userStore";
import { useFavoriteProductStore } from "~/store/favoriteProductStore";

const comparisonStore = useComparisonStore();
const { getProducts: comparisonProducts } = storeToRefs(comparisonStore);

const cartStore = useCartStore();
const { getProductsQuantity: quantity } = storeToRefs(cartStore);

const favoriteProductStore = useFavoriteProductStore();
const { getProducts: favoriteProducts } = storeToRefs(favoriteProductStore);

const modalStore = useModalStore();
const userStore = useUserStore();

onBeforeMount(async () => {
  const promises = [
    new Promise((resolve) => resolve(cartStore.fetch())),
    new Promise((resolve) => resolve(comparisonStore.fetch())),
  ];

  await Promise.all(promises);
});
</script>

<style lang="scss" scoped>
.menu {
  width: 100%;
  max-width: 220px;

  @include flex-container(row, center, center);

  gap: 16px;

  @include bigMobile {
    max-width: 48px;
  }

  &__item {
    @include flex-container(column, center, center);

    @include fixedHW(40px);

    cursor: pointer;

    &.account,
    &.comparison,
    &.favorites {
      @include bigMobile {
        display: none;
      }
    }
  }

  &__item-link {
    @include flex-container(column, center, center);
  }

  &__image {
    position: relative;

    &.account {
      @include fixedHW(40px);

      @include flex-container(row, center, center);

      background-color: white;
      border-radius: 50%;
    }

    & > img {
      @include fixedHW(24px);

      @include bigMobile {
        @include fixedHW(28px);
      }
    }
  }

  &__count {
    width: 21px;
    height: 21px;

    position: absolute;
    right: -70%;
    top: -30%;

    @include flex-container(column, center, center);

    background-color: var(--color-primary-base);

    border-radius: 50%;

    @include bigMobile {
      width: 18px;
      height: 18px;

      right: -40%;
    }
  }

  &__number {
    @include font(10, 13, 400);
    letter-spacing: 0.02em;
    color: white;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
