<template>
  <form class="search-form">
    <div class="search-form__image">
      <img src="~~/assets/icons/search.svg" alt="search" />
    </div>
    <input
      v-model="inputValue"
      type="text"
      class="search-form__input"
      :placeholder="_T('@Product search')"
    />
    <input
      type="submit"
      class="button-primary"
      :value="_T('@Search')"
      @click.stop.prevent="onSearch"
    />
  </form>
</template>

<script setup>
const inputValue = ref("");

const onSearch = () => {
  if (0 !== inputValue.value.trim().length) {
    return navigateTo({
      name: getLocalizedRouteName("search"),
      query: { searchQuery: inputValue.value.trim() },
    });
  }
};
</script>

<style lang="scss" scoped>
.search-form {
  max-width: 700px;
  width: 100%;

  position: relative;

  @include flex-container(row, center, center);

  &__image {
    position: absolute;
    left: 8px;
  }

  &__input {
    width: 100%;

    @include font(16, 22);
    letter-spacing: 0.02em;

    background-color: white;

    border-radius: 30px;

    padding: 8px 100px 8px 40px;

    @include bigMobile {
      padding-right: 8px;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;

      margin: 0;
    }

    &::placeholder {
      @include font(16, 22);
    }
  }
}

.button-primary {
  width: max-content;

  position: absolute;
  right: 0;

  line-height: 20px;

  padding-inline: 16px;

  @include bigMobile {
    height: 0;
    width: 0;

    opacity: 0;
    visibility: hidden;

    padding: 0;
  }
}
</style>
