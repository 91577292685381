<template>
  <ButtonPrimary
    type="button"
    :aria-label="_T('@Goods catalog')"
    @click.prevent="showCatalog"
  >
    <img src="~~/assets/icons/catalog.svg" :alt="_T('@Goods catalog')" />
    <span class="button-catalog__title">{{ _T("@Goods catalog") }}</span>
  </ButtonPrimary>
</template>

<script setup>
import { useHeaderStore } from "~~/store/headerStore";
import ButtonPrimary from "~/modules/shared/buttons/ButtonPrimary.vue";

const header = useHeaderStore();
const modalCatalog = header.modalCatalog;
const activeCatalog = header.activeCatalog;

function showCatalog() {
  if (modalCatalog.active) {
    activeCatalog(false);
    document.body.style.overflow = "auto";
  } else {
    activeCatalog(true);
    document.body.style.overflow = "hidden";
  }
}
</script>

<style lang="scss" scoped>
.button-catalog {
  &__title {
    @include font(16, 18, 700);
    white-space: nowrap;
  }
}
</style>
