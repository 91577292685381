<template>
  <ul class="header-links">
    <li v-for="link in links" :key="link.title">
      <MyLink
        :name="link.name"
        :params="link.params"
        :hash="link.hash"
        class="header-links__item"
        :class="link.class"
      >
        {{ _T(link.title) }}
      </MyLink>
    </li>
  </ul>
</template>

<script setup>
import { useCategoriesStore } from "~/store/categoriesStore";
import { promotionCategoryCode } from "~/config/category.config";

const categoriesStore = useCategoriesStore();
const promotionCategorySlug = categoriesStore.getCategoryByCode(
  promotionCategoryCode,
).slug;

const links = [
  {
    title: "@Promotions",
    name: "category",
    params: {
      categorySlug: promotionCategorySlug,
    },
    class: "bold",
  },
  {
    title: "@Our objects",
    name: "installation-work",
  },
  {
    title: "@Cooperation",
    name: "cooperation",
  },
  {
    title: "@Our stores",
    name: "contacts",
    hash: "#shops",
  },
  {
    title: "@Contacts",
    name: "contacts",
  },
  {
    title: "@Menu guarantee and service",
    name: "warranty",
  },
];
</script>
