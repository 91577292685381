<template>
  <div ref="additional" class="additional-info">
    <HeaderBanner :content="bannerContent" />
    <div class="additional-info__content">
      <div class="additional-info__wrapper">
        <NuxtLink
          :href="`tel:${_T('@Call center number')}`"
          class="additional-info__call ringo-tel"
        >
          <img src="@/assets/icons/phone.svg" alt="call-center" />
          <span class="additional-info__item-name">
            {{ _T("@Call center number") }}
          </span>
        </NuxtLink>
        <RenderCacheable :max-age="86400" :cache-key="getUserLanguage.name">
          <HeaderLinks />
        </RenderCacheable>
      </div>
    </div>
  </div>
</template>

<script setup>
import HeaderBanner from "~/modules/header/sections/HeaderBanner.vue";
import { addScopedEventListener } from "~~/utils/eventListener";
import HeaderLinks from "~/modules/header/sections/HeaderLinks.vue";
import { useLanguageFilterStore } from "~/store/languageFilterStore";

const emits = defineEmits(["getHeightAdditional"]);

const props = defineProps({
  bannerContent: { type: Object, required: true },
});

const languageStore = useLanguageFilterStore();
const { getUserLanguage } = storeToRefs(languageStore);

const additional = ref(null);

function getHeight() {
  emits("getHeightAdditional", additional.value.scrollHeight);
}

onMounted(() => {
  getHeight();
  addScopedEventListener(window, "resize", getHeight);
});
</script>

<style lang="scss" scoped>
.additional-info {
  &__content {
    background-color: white;

    padding: 4px 16px;

    @include bigMobile {
      display: none;
    }
  }

  &__wrapper {
    @extend %width-main;

    @include flex-container(row, space-between, center);

    margin: 0 auto;
  }

  &__call,
  &__location {
    @include flex-container(row, flex-start, center);

    gap: 8px;
  }

  &__item-name {
    @include font(18, 24, 700);
    color: var(--color-primary-base);
  }
}
</style>
